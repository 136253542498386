<template>

    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="my-3">
                        <form @submit.prevent="submitForm" v-if="data.permission.canCreate">
                            <div class="mb-4">
                                <select class="form-select" aria-label="Document-Selection"
                                        v-model="data.inputFormData.documents" @change="changeData">
                                    <option :value="''">{{ translations?.select_document }}</option>
                                    <option :value="item.id" v-for="(item, key) in getDocuments()" :key="key">{{ item.name+ '(V:'+item.version_no+')' }}</option>
                                </select>
                            </div>
                            <div class="treeview" v-if="data.dropdownData.length > 0">
                                <ul>
                                    <li class="border-bottom pb-3 mb-3">
                                        <label class="form-check-label mb-3">
                                            <input class="checkbox-ctl" type="checkbox" aria-checked="true" role="checkbox"
                                                   v-model="data.allCheck" @click="allCheckBoxCheck">
                                            Content Item
                                        </label>
                                    </li>
                                    <li class="border-bottom" v-for="(li, index) in data.dropdownData" :key="index">
                                        <label class="form-check-label mb-3">
                                            <input class="checkbox-ctl" type="checkbox" v-model="li.need_change" true-value="1"
                                                   false-value="0">
                                            {{ li.identifier }}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="projectStatus != 'Completed'">
                                <button class="btn btn-primary" type="submit"
                                    :disabled="invitationData.invitation_status === 'Invited' ? true : false"
                                >{{ translations?.submit }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HelperFunction from "@/common/helpers";
    import {useStore} from "vuex";
    import {useRoute, useRouter} from "vue-router";
    import {computed, inject} from "vue";

    export default {
        name: "ProjectDocumentSection",
        setup() {
            const { data, openModal, storeFormData, formPermissionResolver } = HelperFunction();
            const store = useStore()
            const route = useRoute()
            const router= useRouter()
            const toast = inject('toast')
            const company = store.getters.getSelectedBoard
            const project_id = route.params.id
            data.inputFormData.documents = ""
            data.inputFormData.items = []
            data.allCheck = false

            const invitationData = computed(() => store.getters.getProjectInvitationData);
            const projectStatus = computed(() => store.getters.getProjectStatus);
            data.setURL = vueConfig.Library.Templates.SelectDocumentsEndPoint;

            /** watch **/
            formPermissionResolver('document-selection', true, ['create']);
            /** watch end **/
            const translations = computed(() => {
                return store.state.translations;  
            });
            function getDocuments() {
                return store.getters.getDocuments;
            }
            async function changeData() {
                let params = {
                    company_id: company.id,
                }
                let document_id = data.inputFormData.documents
                // ON CHANGE GETTING DATA ONLY
                await openModal(false, `${data.setURL}/${document_id}/${project_id}`, params)
                .then((res) => {
                    data.inputFormData.documents = document_id
                    if (res.data && res.data.data == null) {
                        toast.warning(res.data.message)
                    }
                })
            }
            function allCheckBoxCheck() {
                data.dropdownData.forEach(function(row) {
                    row.need_change = (row.need_change == 0 ? 1 : 0).toString();
                });
                data.allCheck = !data.allCheck;
            }
            async function submitForm() {
                let validForm = true;
                let formData = {
                    company_id: company.id,
                    project_id: project_id,
                    document_id: data.inputFormData.documents,
                    items: data.dropdownData
                }
                formData.items.forEach(row => {
                    if (row <= 0) {
                        toast.error(
                            "Can't save document without content item"
                        );
                        validForm = false;
                    }
                });
                if (validForm) {
                    //console.error(formData)
                    await storeFormData({url: vueConfig.Project.DocumentEndPoint}, formData)
                    .then((res) => {
                        router.push(`/projects/${project_id}/deliverable-matrix`);
                    })
                }
            }

            return {
                getDocuments, data, changeData,
                allCheckBoxCheck, submitForm, invitationData, projectStatus, translations
            }
        }
    }
</script>

<style scoped>

    .treeview ul li label{
        width: 100%;
        cursor: pointer;
    }

</style>
